import React from 'react';
import Answer from '../answer/Answer';

class Question extends React.Component {

    constructor(props) {
        super(props);
        this.state = {clicked:false, changeAnimation:""};
        this.renderAnswers = this.renderAnswers.bind(this);
    }

    renderAnswers() {
        var html = [];

        var color = "";

        for(var i = 0; i < this.props.answers.length; i++) {

            if(this.props.answers[i].valid){
                color = "green";
            }else{
                color = "red";
            }
            var current = i;
            html.push(<Answer answer={this.props.answers[i].answerText} valid={this.props.answers[i].valid} onCLK={(valid) => {
                if(!this.state.clicked && valid){
                    setTimeout(()=>{if(this.props.onAnswer(100)){ 
                        this.setState({clicked:false, changeAnimation:"start"})
                    }
                        ;}, 1800);
                    this.setState({clicked:true, changeAnimation:"finish"});
                }else{
                    setTimeout(()=>{
                        if(this.props.onAnswer(0)){
                            this.setState({clicked:false});
                        }}, 1800);
                    this.setState({clicked:true});
                }
                //timeout to call next function (i.e. next question)
            }} clicked={this.state.clicked} buttonColorClicked={color}/>);
        }
        return html;
    }

    render() {
       
        return(
        <div>
        <div className={"row question-row " + this.state.changeAnimation} id={this.props.ida}>
           <h3 className="question-hero">{this.props.question}</h3>
        </div>
        <div className="row answer-row">
            {this.renderAnswers()}
        </div>
        </div>
        );
    }

}

export default Question;