import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './App.css';
import Question from '../question/Question';
import Survey from '../survey/Survey';
import QuestionData from '../../data/QuestionData';
import AnswerData from '../../data/AnswerData';

export default function App() {
  return (
    <Router>
      <div className="App">
        {/* <nav className="navigation">
          <ul>
            <li>
              <Link to="/">Splash</Link>
            </li>
            <li>
              <Link to="/Rules">Rules</Link>
            </li>
            <li>
              <Link to="/Quiz">Quiz</Link>
            </li>
          </ul>
        </nav> */}

        <Switch>
          <Route path="/Quiz">
            <Quiz />
          </Route>
          <Route path="/Rules">
            <Rules />
          </Route>
          <Route path="/">
            <Splash />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function Splash() {
  return (
    <div className="splash-screen">
      <span className="be-inspired"></span>
      <Link className="splash-link" to="/Rules"></Link>
    </div>
  );
}

function Quiz() {
  return (
    <div className="survey">
      <Survey questions = {
                getQuestions(10)
            }/>
    </div>
    );
}

function getQuestions(num){
  var allQuestions = [
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Seoul",false), new AnswerData("Tokyo",true), new AnswerData("Istanbul",false)], "tokyo"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Dubai",true), new AnswerData("Moscow",false), new AnswerData("Paris",false)], "dubai"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("London",false), new AnswerData("Milano",false), new AnswerData("New York",true)], "ny"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Milano",false), new AnswerData("Istanbul",true), new AnswerData("Moscow",false)], "istanbul"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Paris",false), new AnswerData("Seoul",true), new AnswerData("Moscow",false)], "seul"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Seoul",false), new AnswerData("Istanbul",true), new AnswerData("Hong Kong",false)], "istanbul"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Paris",true), new AnswerData("Seoul",false), new AnswerData("Moscow",false)], "paris"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Hong Kong",true), new AnswerData("Moscow",false), new AnswerData("New York",false)], "hk"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Ankara",false), new AnswerData("Berlin",true), new AnswerData("Rome",false)], "berlin"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Monako",true), new AnswerData("Seoul",false), new AnswerData("Moscow",false)], "monako"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Seoul",true), new AnswerData("Berlin",false), new AnswerData("Rome",false)], "seul"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Seoul",false), new AnswerData("Tokyo",true), new AnswerData("Istanbul",false)], "tokyo2"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Dubai",true), new AnswerData("Moscow",false), new AnswerData("Paris",false)], "dubai2"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("London",false), new AnswerData("Milano",false), new AnswerData("New York",true)], "ny2"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Milano",false), new AnswerData("Istanbul",true), new AnswerData("Moscow",false)], "istanbul2"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Paris",false), new AnswerData("Seoul",true), new AnswerData("Moscow",false)], "seul2"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Seoul",false), new AnswerData("Istanbul",true), new AnswerData("Hong Kong",false)], "istanbul2"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Paris",true), new AnswerData("Seoul",false), new AnswerData("Moscow",false)], "paris2"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Hong Kong",true), new AnswerData("Moscow",false), new AnswerData("New York",false)], "hk2"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Tokyo",false), new AnswerData("Berlin",true), new AnswerData("Rome",false)], "berlin2"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Monako",true), new AnswerData("Seoul",false), new AnswerData("Moscow",false)], "monako2"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Seoul",true), new AnswerData("Berlin",false), new AnswerData("Rome",false)], "seul2"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Seoul",false), new AnswerData("Tokyo",true), new AnswerData("Istanbul",false)], "tokyo3"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Dubai",true), new AnswerData("Moscow",false), new AnswerData("Paris",false)], "dubai3"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("London",false), new AnswerData("Ankara",false), new AnswerData("New York",true)], "ny3"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Milano",false), new AnswerData("Istanbul",true), new AnswerData("Moscow",false)], "istanbul3"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Paris",false), new AnswerData("Seoul",true), new AnswerData("Moscow",false)], "seul3"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Seoul",false), new AnswerData("Istanbul",true), new AnswerData("Hong Kong",false)], "istanbul3"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Paris",true), new AnswerData("Seoul",false), new AnswerData("Moscow",false)], "paris3"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Hong Kong",true), new AnswerData("Moscow",false), new AnswerData("New York",false)], "hk3"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Tokyo",false), new AnswerData("Berlin",true), new AnswerData("Rome",false)], "berlin3"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Monako",true), new AnswerData("Seoul",false), new AnswerData("Moscow",false)], "monako3"),
    new QuestionData("Cili metropol eshte ne foto?", [new AnswerData("Seoul",true), new AnswerData("Berlin",false), new AnswerData("Rome",false)], "seul3"),

]

  var newQuestions = [];
  while(num > 0){
    newQuestions.push(allQuestions.splice(Math.floor(Math.random() * allQuestions.length), 1)[0]);
    num--;
  }

  return newQuestions;
}

function Rules() {
  return (
  <div className="row rules-of-game">
          <Link className="splash-link" to="/Quiz"></Link>
    <span className="the-rules">
    NE JEMI DUKE BASHKUAR DHE SHPËRBLYER NJERËZIT ME JETË KOZMOPOLITANE.
<br /><br />

DO T’IU SHFAQIM 10 QYTETE; SECILI ME MUNDËSI PERGJIGJIEVE SHUMËFISHTA. MERRË PIKË DUKE QËLLUAR QYTETET E SAKTA.

<br /><br />

SA MË SHUMË PIKË TË QËLLUARA, AQ MË I MIRË DO JETË SHPËRBLIMI.
<br /><br />

ATA QË ARRIJN MË SHUMË PIKË, DO MARRIN SHPËRBLIMIN KRYESOR- AKSESOR TË PËRKRYERË PËR ATA ME STIL JETE KOZMOPOLITANE.
<br /><br />

<b>SUKSESE!</b>
    </span>
  </div>
  );
}


