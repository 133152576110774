import React from 'react';

class Answer extends React.Component {
    constructor(props) {
        super(props);
        var color = "";
        this.state = {objectAnswered: false};
    }

    componentDidUpdate(){
        if(this.state.objectAnswered && !this.props.clicked){
            this.setState({objectAnswered: false});
        }
    }
    
    render() {

        var color = "";
        var opacity = 1;
        if(this.props.clicked){
            if(this.props.valid || this.state.objectAnswered){
                color = this.props.buttonColorClicked;
            } else
            {
                color = "";
                opacity = 0;
            }
        }

        return (
            <div className={"answer " + color} style={{opacity: opacity}} onClick={(e) => {
                if(!this.props.clicked){
                   this.props.onCLK(this.props.valid);
                   this.setState({objectAnswered: true});
                }
            }}> 

               <h4>
               {this.props.answer}
               </h4>


            </div>
        );
    }
}

export default Answer;